import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";
import { slugify } from "../utilities/functions";

const PostNav = styled("nav")`
  margin-bottom: 1em;
`;

export default ({ data: { allArchivesJson: { edges } } }) => {
  const { node: post } = edges[0];
  const { title, author, date, link, content } = post;
  const spotifyButtonHTML = `<a class="btn large" rel="noopener noreferrer" target="_blank" href="https://open.spotify.com/user/1216201900/playlist/4zqRLmX4lxmPbtHvti3B2M" role="button">Open Spotify Playlist</a>`;
  const AuthorComponent =
    author.length > 1 ? (
      <span>
        {author.map(
          (author, index, allAuthors) =>
            index < allAuthors.length - 1 ? `${author}, ` : author
        )}
      </span>
    ) : (
      author
    );
  return (
    <section id="content">
      <header className="navbar">
        <PostNav className="navbar-section">
          <Link to="/archives" className="btn btn-link">
            «&nbsp;Back to Archives
          </Link>
        </PostNav>
      </header>
      <article id={slugify(title)}>
        <header>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <h2>By {AuthorComponent}</h2>
        </header>
        <main
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(content)
              .replace(/\+/g, " ")
              .replace(
                /href="http/g,
                'rel="noopener noreferrer" target="_blank" href="http'
              )
              .replace(
                /<img/g,
                '<img style="display:block;margin:.5em auto;width:100%;max-width:600px;" '
              )
              .replace(spotifyButtonHTML, "")
          }}
        />
      </article>
    </section>
  );
};

export const ArchivePostByPath = graphql`
  query ArchivePostBySlug($archivedLink: String!) {
    allArchivesJson(filter: { link: { eq: $archivedLink } }, limit: 1) {
      edges {
        node {
          title
          author
          date
          excerpt
          link
          content
        }
      }
    }
  }
`;
